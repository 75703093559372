// Require portal config
// import ClientConfig from '_config';

// Setup config export
const config = {
    api: {
      url: 'https://api.dev.dynamicloyalty.ai/graphql'
    },
    workbench: {
      campaign: {
        url: 'http://localhost:6001/dls-platform-dev/us-central1/campaign-api/campaign-api'
      }
    },
    offers: {
      executor: {
        url: 'http://localhost:6001/dls-platform-dev/us-central1/offers-executor/offers-executor'
      }
    }
};

export default config;
