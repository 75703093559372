export const version = "1.0.0";

export const accountId = "";

export const env = "dev";

export const firebaseConfig = {
  apiKey: "AIzaSyAvVQir_zenlt1uzUfkf_sojiGnYmweM-s",
  authDomain: "dls-platform-dev.firebaseapp.com",
  projectId: "dls-platform-dev",
  storageBucket: "dls-platform-dev.appspot.com",
  messagingSenderId: "780621470017",
  appId: "1:780621470017:web:eccc0258eeb9f966b029c0",
  measurementId: "G-YKPF5QRGW3"
};

export const reduxFirebase = {
  enableLogging: "false",
};

export const atlas = {
  serverUrl: "http://localhost:5001/axon-platform/us-central1",
};

export const build = {
  baseUrl: "http://localhost:3100",
};

export const sdk = {
  baseUrl: "https://localhost:9000",
};

export const configs = {
  baseUrl: "https://axon-config.ngrok.io",
};

export const customer = {
  activityStreamTopicName: "customer-activity",
};

export const agent = {
  ipStackAPIAccessKey: "4d1a98985ea36c7d938ee42f111441bf",
};

export default { version, accountId, env, firebaseConfig, reduxFirebase, atlas, build, sdk, configs, customer, agent };
